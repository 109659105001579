.product-item {
  cursor: pointer;
  display: flex;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.product-thumbnail {
  width: 200px;
  height: 200px;
  border-radius: 4px;
  margin: auto;
  padding: 5px;
}

.product-details {
  background-color: #181818;
  padding-left: 1em;
  padding-right: 1em;
  flex: 1;
  align-content: center;
  color: white;
}

a {
  text-decoration: none;
}
