.MuiInputBase-input {
  background: transparent !important;
}

.Mui-disabled {
  background-color: hsl(0, 0%, 95%) !important;
}

.MuiInputBase-input::placeholder {
  color: hsl(0, 0%, 50%) !important;
  opacity: 1 !important;
}

ion-button[disabled] {
  opacity: 1;
}
