.hyperlink {
  text-decoration: "none";
  font-weight: 500;
  color: var(--ion-color-primary, #3880ff);
  cursor: pointer;
}

.detail-table {
  padding: 3%;
  line-height: var(--amplify-line-heights-medium);
  overflow-y: scroll;
}

.detail-table:hover {
  background-color: white;
}
