.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: black;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.box__container {
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  padding: 5rem 20% 5rem 20%;
}

input {
  width: 300px;
  border: 0px solid black;
  border-radius: 5px;
  outline: none;
  margin: 8px 0;
  padding: 12px 20px;
}

/*input:focus {
  border: 1px solid black;
}*/

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.ttf");
}

.background {
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
}
